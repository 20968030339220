import { useRecoilState } from "recoil";
import { showLoaderAtom } from "../store/atom";
import { AnimatePresence, motion } from "framer-motion";
import Lottie from "react-lottie";
import compostyles from "../compostyles/Loader.module.css";

function Loader() {
  const [showLoader, setShowLoader] = useRecoilState(showLoaderAtom);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    path: "/assets/animations/default_loader.json", // アニメーションデータのパス
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <>
      <AnimatePresence>
        {showLoader && (
          <motion.div
            key="loaderDiv"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.1 }} 
            className={compostyles.loader_body}
          >
            <div className={compostyles.default_loader_container}>
              <div className={compostyles.animation_wrap}>
                <Lottie options={defaultOptions}/>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}

export default Loader;

