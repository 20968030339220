import { DotLoaderOverlay } from "react-spinner-overlay";
import { useRecoilState } from "recoil";
import { imgLoaderSrcAtom, showImgLoaderAtom } from "../store/atom";
import { AnimatePresence, motion } from "framer-motion";
import Lottie from "react-lottie";
import compostyles from "../compostyles/ImgLoader.module.css";

function ImgLoader() {
  const [showImgLoader, setShowImgLoader] = useRecoilState(showImgLoaderAtom);
  const [imgLoaderSrc, setImgLoaderSrc] = useRecoilState(imgLoaderSrcAtom);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    path: "/assets/animations/imgloader.json", // アニメーションデータのパス
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <AnimatePresence>
        {showImgLoader && (
          <motion.div
            key="imgLoaderDiv"
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.1 }} 
            className={compostyles.loader_body}
          >
            <div className={compostyles.default_loader_container}>
              <div className={compostyles.animation_wrap}>
                <div className={compostyles.oshi_img}>
                  <img
                    src={
                      imgLoaderSrc ? imgLoaderSrc : "/assets/img/jee_normal.png"
                    }
                    alt=""
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "/assets/img/jee_normal.png";
                      setImgLoaderSrc('');
                    }}
                  />
                </div>
                <Lottie options={defaultOptions} />
              </div>
              <div className={compostyles.welcome_title}>
                <div className={compostyles.welcome}>
                  <span>W</span>
                  <span>e</span>
                  <span>l</span>
                  <span>c</span>
                  <span>o</span>
                  <span>m</span>
                  <span>e</span>
                  <span>!</span>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}

export default ImgLoader;
