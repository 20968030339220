import { DotLoaderOverlay } from "react-spinner-overlay";
import { useRecoilState } from "recoil";
import { showSplashLoaderAtom } from "../store/atom";
import { AnimatePresence, motion } from "framer-motion";
import Lottie from "react-lottie";
import compostyles from "../compostyles/SplashLoader.module.css";

function SplashLoader() {
  const [showSplashLoader, setShowSplashLoader] =
    useRecoilState(showSplashLoaderAtom);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    path: "/assets/animations/splashJee.json", // アニメーションデータのパス
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <>
      <AnimatePresence>
        {showSplashLoader && (
          <motion.div
            key="splashLoaderDiv"
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.1 }} 
            className={compostyles.loader_body}
          >
            <div className={compostyles.jee_loader_container}>
              <div className={compostyles.animation_wrap}>
                <Lottie options={defaultOptions}/>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}

export default SplashLoader;
