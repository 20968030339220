import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import router from "./router/Router";
import { SnackbarProvider } from "notistack";
import { RecoilRoot } from "recoil";
import Loader from "./component/Loader";
import SplashLoader from "./component/SplashLoader";
import ImgLoader from "./component/ImgLoader";

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);

root.render(
  // <React.StrictMode>
  <RecoilRoot>
    <SnackbarProvider maxSnack={2}>
      <RouterProvider router={createBrowserRouter(router)} />
    </SnackbarProvider>
    <Loader />
    <SplashLoader />
    <ImgLoader />
  </RecoilRoot>
  // </React.StrictMode>
);
