import { atom } from "recoil";

//ログイン情報
export const loginInfoAtom = atom({
  key: "loginInfoAtom",
  default: {},
});

//メニュー現在地
export const currentMenuAtom = atom({
  key: "currentMenuAtom",
  default: "creator",
});

//ローディング状態
export const showLoaderAtom = atom({
  key: "showLoaderAtom",
  default: false,
});

//ローディング状態(スプラッシュ式）
export const showSplashLoaderAtom = atom({
  key: "showSplashLoaderAtom",
  default: false,
});

//ローディング状態(画像式）
export const showImgLoaderAtom = atom({
  key: "showImgLoaderAtom",
  default: false,
});

//ローディング状態(画像式）の画像パス
export const imgLoaderSrcAtom = atom({
  key: "imgLoaderSrcAtom",
  default: '',
});


//ページモーション動作状態
export const pageMotionModeAtom = atom({
  key: "pageMotionModeAtom",
  default: "normal",
});

//サポーターマイページ ハンバーガー開閉
export const openHamMenuMAtom = atom({
  key: "openHamMenuMAtom",
  default: false,
});

//クリエイターマイページ ハンバーガー開閉
export const openHamMenuCAtom = atom({
  key: "openHamMenuCAtom",
  default: false,
});
